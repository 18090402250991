.EPiServerForms {
    .Form__Element {
        margin-bottom: 35px;

        .FormTextbox {
            input {
                &:focus {
                    outline: 3px auto $color--contrast-blue;
                }
            }
        }

    }

    .Form__Success__Message {
        // NOTE: `!important` is used here because Episerver injects a style sheet that has more specific styling of `Form__Success_Message`.
        padding: 0px !important;
        background-color: $color--neutral-5 !important;
        padding: 16px !important;
    }

    .Form__Element__Caption {
        // NOTE: `!important` is used here because Episerver injects a style sheet that has more specific styling of `Form__Element__Caption`.
        display: block !important;
        margin: 0 0 5px 0 !important;
        position: relative !important;
        font-size: 14px !important;
        margin-bottom: 8px !important;
        line-height: 20px !important;
        font-weight: bold !important;

        @include smaller-than($bp--mobile) {
            left: 0 !important;
        }
    }

    .Form__Element__ValidationError {
        float: right;
        color: $color--norway-red !important;
        margin-top: 4px;
        font-style: italic;
        font-weight: 300;
    }

    .Form__Title {
        margin: 40px 0 20px 0;
    }

    .Form__Description {
        margin: 0 0 20px 0;
        width: 100%;
        float: none;
    }

    input[type="text"],
    input[type="url"],
    input[type="number"],
    textarea {
        padding: 15px;
        width: 100%;
        min-height: 40px;
        font-family: inherit;
        font-weight: 500;
        background-color: $color--white;
        border: 1px solid $color--neutral-70;
        border-radius: 2px;
        width: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-appearance: none;
    }

    .FormTextbox__Input {
        min-height: 268px;
    }

    .FormRange .FormRange__Input {
        display: block;
        width: 100%;
    }

    .FormResetButton {
        @extend .btn;
    }

    .FormSubmitButton {
        @extend .btn;

        min-width: 215px;
    }

    .FormCaptcha {
        display: flex;
        flex-direction: column;

        .FormCaptcha__Refresh {
            background: none;
            appearance: none;
            border: 0;
            border-radius: 0;
            color: $color--contrast-blue;
            text-decoration: underline;
            align-self: flex-start;
            padding: 0;
            cursor: pointer;
            font-size: 0.875rem !important;

            &:hover {
                text-decoration: none;
            }
        }

        .FormCaptcha__Image {
            flex: none;
            margin: 10px 0;
            align-self: flex-start;
        }
    }
}
