.panels {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;

    &.panels--fluid {
      align-items: stretch;

      .panel {
        flex: 1 0 33.33%;
      }
    }

    h2 {
        font-size: 1.125rem;
    }

    h3 {
        font-size: 1rem;
    }

    p {
        font-size: 1rem;
    }

    .panel {
        flex: 0 0 33.33%;

        &.panel--large {
            flex: 0 0 100%;
        }
        @include smaller-than($bp--mobile) {
            flex: 0 0 50%;
        }
        @include smaller-than($bp--mini) {
            flex: 0 0 100%;
        }
    }
}
