//.rds-[type[direction]]--[size]

.np-margin-bottom--m {
    margin-bottom: 2rem;
}

.np-margin-top--m {
    margin-top: 2rem;
}

.np-margin-left--m {
    margin-left: 2rem;
}

.np-margin-right--m {
    margin-right: 2rem;
}

.np-margin-x--m {
    margin-left: 2rem;
    margin-right: 2rem;
}

.np-margin-y--m {
    margin-top: 2rem;
    margin-bottom: 2rem;
}