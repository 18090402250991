﻿.columns {
    .column {
        float: left;
        margin: .5rem 5% .5rem 0;
    }

    .column-33 {
        width: 28.33%;

        @include smaller-than($bp--mobile) {
            margin: 0;
            width: 100%;
            display: block;
        }
    }

    .column-50 {
        width: 47.5%;

        &:nth-child(2n) {
            margin: .5rem 0;
        }

        @include smaller-than($bp--mobile) {
            margin: 0;
            width: 100%;
            display: block;
        }
    } 

    &::after {
        clear: both;
        display: table;
        content: '';
    }
}
