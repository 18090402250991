﻿.padded {
    padding: 30px 0;
}

.padded--xsmall {
    padding: .5rem 0;
}

.padded--small {
    padding: 1rem 0;
}

.padded--medium {
    padding: 2rem 0;
}

.padded--large {
    padding: 3rem 0;
}

.padded--xlarge {
    padding: 4rem 0;
}
