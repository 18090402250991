figure {
    margin: 0 0 1rem;

    img {
        display: block;
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 600px;
    }

    figcaption {
        @include figcaption;
        @include smaller-than($bp--mobile) {
            font-weight: 400;
        }
    }
}

.image-caption {
    @include figcaption;
    float: right;
    font-weight: 400;

    @include smaller-than($bp--mobile) {
        font-size: 0.75rem;
    }
}
