p {
    font-weight: 400;
    line-height: 1.58;
    letter-spacing: -0.003em;
    margin: 0 0 0.5rem;
}

ul li {
    padding-bottom: 0.75rem;
    line-height: 1.58;
}

h2 {
    font-size: 1.375rem; // 22px
    line-height: 1.875rem; //30px
    font-weight: 600;
    margin: 1.5rem 0 0.5rem;
}

h3 {
    font-weight: 600;
    font-size: 1.125rem; // 18px
    line-height: 1.625rem; // 26px
    margin: 1.25rem 0 0.5rem;
}

h4 {
    font-weight: 600;
    font-size: 1rem; // 16px
    line-height: 1.375rem; // 16px;
    margin: 1rem 0 0.5rem;
}

.text-center {
    display: block;
    text-align: center;
}

small,
.small {
    font-size: 0.875rem; // 14px;
    font-weight: 600;
}

b,
strong,
.bold {
    font-weight: 600;
}

/* ARTICLE TYPOGRAPHY */

article {
    h1 {
        font-weight: 400;
        font-size: 2.25rem; //36px
        line-height: 3.0625rem; //49px
        margin: 0;

        @include smaller-than($bp--mobile) {
            font-size: 1.5rem;
            line-height: 1.875rem;
        }
    }

    p {
        font-size: 1rem; // 16px
        line-height: 1.625rem; // 26px
        margin-bottom: 1rem;

        @include smaller-than($bp--mobile) {
            font-size: 1rem;
        }
    }

    li {
        font-size: 1rem;
    }

    .ingress {        
        @include ingress;
        margin: 0.5rem 0 1.5rem 0;        

        @include smaller-than($bp--mobile) {
            font-size: 1.125rem; //18px
            line-height: 1.6875rem; //27px
        }
    }

    .publine {
        font-size: rem-calc(14px);
        line-height: rem-calc(20px);
        color: $color--seperator;
        font-weight: 400;
    }
}

/* SECTION TYPOGRAPHY */
section {
    .h1 {
        text-transform: uppercase;
        display: block;
        font-size: 1.125rem;
        font-weight: 600;
        padding: 0.5rem 0;
        text-align: center;
        color: $color--font;

        &::after {
            transition: width 0.3s ease-in-out;
            background-color: $color--contrast-blue;
            content: "";
            display: block;
            height: 2px;
            margin: 0.5rem auto;
            width: 70px;
        }

        &:hover::after {
            width: 90px;
        }
    }
}
