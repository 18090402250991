﻿section,
.section {
  &::after {
    display: table;
    clear: both;
    content: "";
  }

  &.gray {
    background-color: $color--secondary;
  }

  &.dark {
    background-color: #424040;
    color: #fff;
  }

  &.white {
    background-color: $color--white;
  }

  &.light {
    background-color: $color--neutral-5;
  }

  &.neutral-light {
    background-color: $color--neutral-10;
  }

  &.almost-light {
    background-color: #f4f1f0;
  }

  &.carousel-wrapper {
    background: linear-gradient(to bottom, #f6f6f6 0%,#f6f6f6 80%,transparent 80%,transparent 100%)
  }
}

.section__title {
  margin: 0 0 40px 0;
}