// Neutral colors
$color--white: #ffffff;
$color--light-gray: #f4f4f4;
$color--seperator-light: #c6c6c6;
$color--seperator: #656565;
$color--secondary: #3e3e3e;
$color--dark-gray: #333333;
$color--black: #000000;

//Arctic Blue
$color--arctic-blue: #20788c;
$color--arctic-blue-lighter: #a2ccd2;
$color--arctic-blue-light: #56adb8;
$color--arctic-blue-dark: #0e465e;

//Passion Red
$color--passion-red: #c61c08;
$color--passion-red-light: #ee3c30;
$color--passion-red-dark: #960004;

// Applied colors
$color--font: $color--dark-gray;

//NEW pallett

$color--norway-blue: (#00205b);
$color--norway-red: (#c8102e);

$color--contrast-blue: (#004de8);
$color--contrast-green: (#005505);
$color--contrast-yellow: (#fffc8d);

$color--contrast-green-7: (#edf3ee);
$color--norway-red-7: (#fbeef0);

$color--contrastblue-40: (#99b8f6);
$color--contrastblue-25: (#bfd2f9);
$color--contrastblue-15: (#d9e5ff);
$color--contrastblue-10: (#e6edfd);

$color--neutral-70: (#525252);
$color--neutral-50: (#757575);
$color--neutral-40: (#9e9e9e);
$color--neutral-20: (#d9d9d9);
$color--neutral-10: (#f1f1f1);
$color--neutral-5: (#f6f6f6);
