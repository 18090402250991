﻿main {
  a,
  a:visited {
    color: $color--contrast-blue;
    text-decoration: none;
    word-wrap: break-word;
  }

  p,
  span,
  li {
    a,
    a:visited {
      border-bottom: 1px solid $color--contrast-blue;
      text-decoration: none;
    }

    a:hover {
      color: $color--norway-blue;
      border-bottom: 1px solid $color--norway-blue;
    }
  }
}

.undecorated {
  text-decoration: none;
}

.related-link-block-element {
  padding: 1rem;
  background: $color--neutral-5;
  border-bottom: 1px solid $color--neutral-20;
  margin-bottom: 0.2em;
}

p {
  font-size: 1rem;
  margin: 1rem;
}

.arrow-link {
  padding-left: 14px;
  a,
  a:visited {
    padding: 0 2px;
    position: relative;
    border-bottom: none;
    font-size: 1rem;
    font-weight: 400;

    &::before {
      background-image: url("../images/lenkepil-contrast-bla-2019.svg");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: inline-block;
      margin: 2px 0;
      height: 11px;
      width: 11px;
      position: absolute;
      transform: translate(-14px, 5px);
      transition: transform 0.2s ease-in-out;
    }
    &:hover {
      color: $color--norway-blue;
      border-bottom: none;
      &::before {
        background-image: url("../images/lenkepil-norway-bla-2019.svg");
        transform: translate(-11px, 5px);
      }
    }
  }
}

.view-all-btn {
  display: inline-block;
  margin: 2rem auto 1rem;
  text-align: center;
  clear: both;
}
