@mixin larger-than($breakpoint) {
    @media all and (min-width: $breakpoint) {
        @content;
    }
}

@mixin smaller-than($breakpoint) {
    @media all and (max-width: ($breakpoint - 1px)) {
        @content;
    }
}

@mixin between($small, $big) {
    @media all and (max-width: ($big - 1px)) and (min-width: $small) {
        @content;
    }
}

// 1280px to Infinity px
@mixin desktop() {
    @media all and (min-width: $bp--tablet) {
        @content;
    }
}

// 768px to 1279
@mixin tablet() {
    @media all and (min-width: $bp--mobile) and (max-width: ($bp--tablet - 1px)) {
        @content;
    }
}

// 360px to 767px
@mixin mobile() {
    @media all and (min-width: $bp--mini) and (max-width: ($bp--mobile - 1px)) {
        @content;
    }
}

// 0px to 359px
@mixin mini() {
    @media all and (max-width: ($bp--mini - 1px)) {
        @content;
    }
}
