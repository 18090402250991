﻿// $path-to-font: "";

$OpenSansPath: "../fonts/open-sans" !default;
$OpenSansVersion: "1.1.0" !default;

@mixin fontdef($FontType: "Regular") {
    src: url('#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.eot?v=#{$OpenSansVersion}');
    src: url('#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.eot?#iefix&v=#{$OpenSansVersion}') format('embedded-opentype'), url('#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.woff2?v=#{$OpenSansVersion}') format('woff2'), url('#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.woff?v=#{$OpenSansVersion}') format('woff'), url('#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.ttf?v=#{$OpenSansVersion}') format('truetype'), url('#{$OpenSansPath}/#{$FontType}/OpenSans-#{$FontType}.svg?v=#{$OpenSansVersion}##{$FontType}') format('svg');
    font-display: swap;
}

// Open Sans 300
@font-face {
    font-family: 'Open Sans';
    @include fontdef("Light");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    @include fontdef("Regular");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    @include fontdef("Semibold");
    font-weight: 600;
    font-style: normal;
}
