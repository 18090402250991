﻿.container-large {
    max-width: 1044px;
    padding: 0 20px;
    margin: 0 auto;
}

.container-small {
    max-width: 540px;
    padding: 0 20px;
    margin: 0 auto;

    @include smaller-than($bp--tablet) {
        max-width: 600px;
    }
}

.container {
    max-width: 980px;
    padding: 0 104px;
    margin: 0 auto;

    @include smaller-than($bp--tablet) {
        padding: 0 20px;
    }
}