﻿* {
    box-sizing: inherit;
    font-family: inherit;
}

html {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: $base-font-size;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    height: 100%;
}

body {
    margin: 0;
    color: $color--font;

    &.locked {
        overflow: hidden;
        position: relative;
        width:100%;

        @include smaller-than(768px) {
            position: fixed;
        }
    }
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

.skipToContent {
    position: absolute;
    top: auto;
    left: -10000px;
    width: 100%;
    z-index: 100;
    text-align: center;
    padding: .5rem 0;
    background-color: #fff;
    border-top: #000;

    &:focus {
        left: 0px;
    }
}

*:focus {
    outline: 3px auto $color--norway-red;
}
